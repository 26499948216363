@import "src/styles/core/reset";


.UpcomingMeetings{
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.UpcomingMeetings-container{
  width: 100%;
  max-width: 700px;
}

.UpcomingMeetings-header{
  font-size: 1.6em;
  margin: 10% 0 5% 10%;
}

.UpcomingMeetings-subheader{
  font-size: 1.2em;
  margin-left: 10%;
  margin-top:5%;
}
