
.Profile{
  width: 100%;

  display: flex;
  flex-direction: column;
}

.Profile-logo-container{
  width: 100%;
  height: 43vh;
}

.Profile-name-container{
  width: 100%;
  display: flex;
  height: 6vh;
  justify-content: center;
  margin-top: 10%;
}

.Profile-settings-container{
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.Profile-user-name{
  width: 80%;
  font-size: 1.6em;
  color: #b81f40;
  max-width: 500px;
}

.Profile-button-container{
  width: 100%;
  height: 20vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

