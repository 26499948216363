.IconHeader{
  width: 100%;
}

.IconHeader-header-container{
  width: 100%;
}

.IconHeader-leave-icon{
  width: 35px;
  float: right;
  margin: 20px;
}