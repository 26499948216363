.IconHeader-diversity-logo-container{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  color: #b81f40;
}

.IconHeader-title-container{
  text-align: right;
}

.IconHeader-diversity-icon {
  width: 40%;
}

.IconHeader-title{
  font-size: 2em;
  margin-top:2%;
}

.IconHeader-title-powered-by-container{
  display: flex;
  justify-content: flex-end;
}

.IconHeader-title-powered-by{
  font-size: 0.7em;
  margin-top: 1%;
  color: black;
}

.IconHeader-title-brockhaus-ag{
  font-size: 0.7em;
  margin-top: 1%;
  color: #575757;
}

.IconHeader-subtitle{

}