@import "src/styles/core/reset";

@font-face {
  font-family: "GilroyRegular";
  src: url('../../resources/fonts/Gilroy-Regular.otf') format("trueType");
  font-weight: normal;
}

@font-face {
  font-family: "GilroyLight";
  src: url('../../resources/fonts/Gilroy-Light.otf') format("trueType");
  font-weight: normal;
}

@font-face {
  font-family: "GilroyMedium";
  src: url('../../resources/fonts/Gilroy-Medium.otf') format("trueType");
  font-weight: normal;
}

@font-face {
  font-family: "SourceSansProRegular";
  src: url('../../resources/fonts/SourceSansPro-Regular.otf') format("trueType");
  font-weight: normal;
}
