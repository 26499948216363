
.Characteristic {
  width: 80%;
  max-width: 500px;

  padding: 2%;
  margin-top: 5%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.Characteristic-attribute {
  color: #b81f40;
  font-size: 0.9em;
}


.Characteristic-left-container{
  width: 80%;
}

.Characteristic-right-container {

}


.Characteristic-value {
  margin-top: 5%;
  margin-bottom: 5%;
  font-size: 1.2em;
}


.Characteristic-icons {
  width: 25px;
  cursor: pointer;
}

.Characteristic-input {
  width: 90%;
  padding: 2%;
  margin-top: 2%;
  font-size: 1.2em;
}