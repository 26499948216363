.Information {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Information-info-text {
  max-width: 90%;
  margin-top: 10%;
  line-height: 18px;
  margin-bottom: 20%;
}
