.DropdownQuestion{
  width: 80%;
  padding: 5%;
  margin: 3%;
}

.DropdownQuestion-question{
  margin-bottom: 4%;
}

.DropdownQuestion fieldset {
  border-radius: 0;
}
